import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Action from '../components/Action';
import Video from '../components/Video';
export const _frontmatter = {
  "title": "Service Workies Ch4 — Cache",
  "date": "2019-08-16",
  "promo": "serviceworkies",
  "description": "Service Worker Caching",
  "color": "#b9b9b9"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` chapter 4 is released and ready to play!`}</p>
    <p>{`In chapters one through three you've learned the Service Worker basics — how to manage lifecycles, react to events, and intercept requests. You've got the fundamentals down. You've eaten your veggies. Now it's time for dessert!`}</p>
    <h2>{`Flush with Cache`}</h2>
    <p>{`Service Workers enable your website or web app to work offline — through the use of caches. Your Service Worker caches the resources your page needs, so that when the user has no internet connection your Service Worker can return resources directly from the cache.`}</p>
    <p>{`In this chapter you'll learn the ins and outs of creating, populating and deleting caches. You'll learn how to retrieve items from the cache, and how to version your caches so that you can `}<a parentName="p" {...{
        "href": "https://web.dev/service-worker-mindset#together-but-separate"
      }}>{`avoid conflicts`}</a>{`.`}</p>
    <Video src="/video/populate-cache.mp4" mdxType="Video" />
    <p>{`But beware! Not everyone wants to see you succeed...`}</p>
    <Video src="/video/ch4-death.mp4" mdxType="Video" />
    <h2>{`Good luck have fun`}</h2>
    <p>{`Have a blast playing chapter four and learning how to use caches!`}</p>
    <Action link="https://serviceworkies.com" mdxType="Action">Learn Caching Now</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      